import ReactDOM from "react-dom/client";

import "./assets/css/aui-pc.css";
import "./assets/css/aui-flex.css";
import "./assets/css/aui-font.css";
import "./assets/css/aui-margin.css";
import "./assets/css/aui-padding.css";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
// import Register from "./register";
import reportWebVitals from "./reportWebVitals";
import Route from "./router/index";
import { BrowserRouter } from "react-router-dom";

import "./assets/css/antd.css";
moment.locale("zh-cn");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Route></Route>
    </BrowserRouter>
  </ConfigProvider>
  // </React.StrictMode>
);

/* 
  <ConfigProvider locale={zhCN}>
      <div style={{ width: 400, margin: '100px auto' }}>
        <DatePicker onChange={handleChange} />
        <div style={{ marginTop: 16 }}>
          当前日期：{date ? date.format('YYYY年MM月DD日') : '未选择'}
        </div>
      </div>
    </ConfigProvider>

    import { ConfigProvider } from "antd"
import zhCN from "antd/lib/locale/zh_CN"
 
<ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,

*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
