import invoiceDetailLess from "./css/invoiceDetail.less";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { memo, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import ajax from "../../assets/js/ajax";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();

  const params = useLocation().state as {
    id: string;
  };

  const [data, setData] = useState({
    statusDesc: "",
    eleInvoiceUrl: "",
    remark: "",
    amount: "",
    invoiceTypeName: "",
    invoiceHead: "",
    unifyCreditCode: "",
    bankDot: "",
    bankCardNo: "",
    addr: "",
    linkPhone: "",
    recipient: "",
    recipientPhone: "",
    email: "",
    address: "",
    tradeNo: "",
    insureOrderNo: "",
    guaranteeCategoryName: "",
  });

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/invoiceOrder/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="aui-padded-b-12">
        <span className="aui-font-size-16 aui-192530 aui-padded-r-4">
          发票详情
        </span>
      </div>
      <div className={invoiceDetailLess.dg__card}>
        <p className={invoiceDetailLess.title}>开票状态</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="状态">{data.statusDesc}</Dglistitem>
          <Dglistitem label="附件">
            <a href={data.eleInvoiceUrl} target="_blank">
              {data.eleInvoiceUrl ? "附件" : ""}
            </a>
          </Dglistitem>
          <Dglistitem label="备注">{data.remark}</Dglistitem>
        </Dglist>
      </div>
      <div className={invoiceDetailLess.dg__card}>
        <p className={invoiceDetailLess.title}>开票信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="开票金额（元）">{data.amount}</Dglistitem>
          <Dglistitem label="开票类型">{data.invoiceTypeName}</Dglistitem>
          <Dglistitem label="开票抬头">{data.invoiceHead}</Dglistitem>
          <Dglistitem label="税务登记号">{data.unifyCreditCode}</Dglistitem>
          <Dglistitem label="开户行 ">{data.bankDot}</Dglistitem>
          <Dglistitem label="银行账号">{data.bankCardNo}</Dglistitem>
          <Dglistitem label="企业地址">{data.addr}</Dglistitem>
          <Dglistitem label="企业电话">{data.linkPhone}</Dglistitem>
        </Dglist>
      </div>
      <div className={invoiceDetailLess.dg__card}>
        <p className={invoiceDetailLess.title}>邮寄信息</p>
        <Dglist column={2} labelWidth="200px">
          {/* <Dglistitem label="收件人">{data.recipient}</Dglistitem> */}
          <Dglistitem label="联系电话">{data.recipientPhone}</Dglistitem>
          <Dglistitem label="电子邮箱">{data.email}</Dglistitem>
          {/* <Dglistitem label="收件地址">{data.address}</Dglistitem> */}
          {/* <Dglistitem label="邮政编码 ">{"--"}</Dglistitem> */}
        </Dglist>
      </div>
      <div className={invoiceDetailLess.dg__card}>
        <p className={invoiceDetailLess.title}>订单信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
          <Dglistitem label="保函编号">{data.insureOrderNo}</Dglistitem>
          <Dglistitem label="保函品种">{data.guaranteeCategoryName}</Dglistitem>
        </Dglist>
      </div>
      <div className="aui-text-center aui-margin-t-32">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/invoices");
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
});
