import { Button, DatePicker } from "antd";
// import type { DateType } from "antd";
import moment from "moment";
import Header from "../components/header";
import Footer from "../components/footer";
import homeLess from "./home.less";
import { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Chart } from "@antv/g2";
import userheadimg from "@/assets/img/userheadimg.png";
import ajax from "../assets/js/ajax";

import EmptyPage from "../components/emptyPage";
import { hasPermission } from "../assets/js/hasPermission";

let chart: any = null;
export default memo(() => {
  const navigate = useNavigate();
  let [info, setInfo] = useState({
    realName: "",
    balance: 0,
  });
  let [financialInstitution, setFinancialInstitution] = useState({
    statusName: "",
  });
  let [time, setTime] = useState<string>(moment().format("YYYY"));

  useEffect(() => {
    getMonthData();
  }, [time]);

  /**
   * @author 伟健 / 张
   * @name 初始化 图表
   * @param
   * @return
   */
  let initChart = (data: any[]) => {
    chart.data(data);
    let maxNum = Math.max.apply(null, [
      ...data.map((el: any) => {
        return el.consumeAmount;
      }),
      ...data.map((el: any) => {
        return el.callNum;
      }),
    ]);
    chart.scale(
      {
        callNum: {
          min: 0,
          max: maxNum + 10,
        },
        consumeAmount: {
          min: 0,
          max: maxNum + 10,
        },
      },
      {
        nice: true,
      }
    );
    chart.axis("consumeAmount", false);
    chart.legend({
      custom: true,
      items: [
        {
          name: "调用次数",
          value: "callNum",
          marker: {
            symbol: "line",
            style: { stroke: "#1890ff", lineWidth: 2 },
          },
        },
        {
          name: "消费金额",
          value: "consumeAmount",
          marker: {
            symbol: "line",
            style: { stroke: "#2fc25b", lineWidth: 2 },
          },
        },
      ],
    });

    chart.line().position("time*callNum").color("#1890ff");
    chart.line().position("time*consumeAmount").color("#2fc25b");

    chart.removeInteraction("legend-filter"); // 自定义图例，移除默认的分类图例筛选交互
    chart.render();
  };

  /**
   * @author 伟健 / 张
   * @name 当前用户信息
   * @param
   * @return
   */
  let getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));
        setInfo(res.user || {});
        setFinancialInstitution(res.financialInstitution || {});
      },
      fail: () => {
        navigate("/");
      },
    });
  };
  /**
   * @author 伟健 / 张
   * @name 统计数据
   * @param
   * @return
   */
  let getMonthData = () => {
    ajax.get({
      url: "/openPlatform/homePageData/homePageMonthData",
      params: {
        year: time,
      },
      self: this,
      success: (res) => {
        console.log(res.table);
        initChart(res.table);
      },
      fail: () => {},
    });
  };
  /**
   * @author 伟健 / 张
   * @name 手动更新
   * @param
   * @return
   */
  let updateData = () => {
    ajax.get({
      url: "/openPlatform/homePageData/updateData",
      data: {},
      self: this,
      success: (res) => {
        getMonthData();
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    // if (hasPermission("openPlatform:homePageData:homePageMonthData")) {
    chart = new Chart({
      container: "chart",
      autoFit: true,
      height: 500,
      padding: [30, 20, 70, 30],
    });
    // }
    getCurrentInfo();
    getMonthData();
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <Header info={info}></Header>
        <div>
          <div className={homeLess.wrap}>
            <div className="w-1200 aui-margin-auto aui-padded-t-18 aui-flex aui-row-between aui-padded-b-40">
              <div className={homeLess.chart}>
                <div className="aui-flex aui-row-between aui-padded-t-30 aui-padded-l-30 aui-padded-r-30">
                  <div className="">
                    <span>数据概况</span>
                    <span
                      className="aui-link aui-padded-l-6"
                      onClick={() => updateData()}
                    >
                      更新
                    </span>
                  </div>
                  <div>
                    <DatePicker
                      defaultValue={moment(time, "YYYY")}
                      onChange={(time, timeString) => {
                        setTime(timeString);
                      }}
                      picker="year"
                    />
                  </div>
                </div>
                <div id="chart"></div>
              </div>
              <div className={homeLess.account}>
                <div className={homeLess.accountInfo}>
                  <div className="aui-flex aui-row-between aui-col-center">
                    <div className="aui-flex aui-col-center">
                      <img
                        src={userheadimg}
                        alt=""
                        className={homeLess.userHeadimg}
                      />{" "}
                      <span className="aui-padded-l-6">
                        {info.realName || "--"}
                      </span>
                    </div>
                    <div>
                      <p
                        onClick={() => {
                          navigate("/admin");
                        }}
                        className={homeLess.userCenter}
                      >
                        用户中心
                      </p>
                    </div>
                  </div>
                  <div className="aui-padded-t-10">
                    <i className="icon"></i> 企业状态：{" "}
                    <span>{financialInstitution.statusName || "--"}</span>
                  </div>
                </div>
                <div className="aui-padded-t-30 aui-padded-b-26 aui-padded-l-16 aui-padded-r-16 aui-bg-white">
                  <p className="aui-font-size-14 aui-999CA1">
                    当前可用额度(元)
                  </p>
                  <div className="aui-flex aui-row-between aui-col-center aui-padded-t-18">
                    <div>
                      <span className="aui-font-size-24 aui-font-weight-600 aui-color-main">
                        {info.balance}
                      </span>
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          navigate("/admin/recharge");
                        }}
                        type="primary"
                      >
                        充值
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate("/admin/product");
                  }}
                  className={`aui-margin-t-12 aui-padded-t-30 aui-padded-r-16 aui-padded-l-16 ${homeLess.box_bg}`}
                >
                  <div className="aui-flex aui-row-between">
                    <div className="aui-flex">
                      <div>
                        <p className={homeLess.icon_box}>
                          <i className="icon aui-font-size-26 aui-C8010B">
                            &#xe61b;
                          </i>
                        </p>
                      </div>
                      <div className="aui-padded-l-10">
                        <p className="aui-font-size-14 aui-192530">产品中心</p>
                        <p className="aui-font-size-12 aui-padded-t-4 aui-757575">
                          OFD文件生成、企业信息查询
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <i className="icon">&#xe646;</i>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate("/admin/order");
                  }}
                  className={`aui-margin-t-12 aui-padded-t-30 aui-padded-r-16 aui-padded-l-16 ${homeLess.box_bg}`}
                >
                  <div className="aui-flex aui-row-between">
                    <div className="aui-flex">
                      <div>
                        <p className={homeLess.icon_box}>
                          <i className="icon aui-font-size-26 aui-C8010B">
                            &#xe602;
                          </i>
                        </p>
                      </div>
                      <div className="aui-padded-l-10">
                        <p className="aui-font-size-14 aui-192530">我的订单</p>
                        <p className="aui-font-size-12 aui-padded-t-4 aui-757575">
                          OFD文件生成、企业信息查询
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <i className="icon">&#xe646;</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
        {/* {hasPermission("openPlatform:homePageData:homePageMonthData") ? (
          
        ) : (
          <EmptyPage />
        )} */}
      </div>
    </div>
  );
});
